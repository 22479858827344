<template>
    <div>
        <!-- <v-row style="margin-bottom: 15px; margin-top: 10px;"> -->
        <v-row v-if="!hideHeader" dense class="mx-1 px-0">
            <h4 class="ml-3 my-auto py-auto">{{ pageTitle }}</h4>
            <v-spacer />

            <slot name="actions" v-bind="{ data }" />

            <v-btn
                v-if="showRefreshButton"
                class="my-auto"
                icon
                small
                title="Refresh"
                @click.stop="refresh"
                key="5">
                <v-icon small>mdi-refresh</v-icon>
            </v-btn>
            <v-btn
                v-if="showAddButton"
                :disabled="!$canEdit(navigation)"
                class="my-auto"
                icon
                small
                title="Add"
                @click.stop="add"
                key="6">
                <v-icon small>mdi-plus</v-icon>
            </v-btn>
            <v-btn
                v-if="showEditButton"
                :disabled="!$canEdit(navigation)"
                class="my-auto"
                icon
                small
                title="Edit"
                @click.stop="toggleEdit"
                key="7">
                <v-icon v-if="isEditing" small>mdi-pencil-off</v-icon>
                <v-icon v-else small>mdi-pencil</v-icon>
            </v-btn>
            <v-menu 
                v-if="showSettings || canExportCSV || archiveBladeName != null"
                offset-y 
                :close-on-content-click="false"
                key="9">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="my-auto"
                        title="Settings"
                        small
                        key="10">
                        <v-icon small>mdi-cog</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item v-if="archiveBladeName != null" @click="toArchives" :dense="dense">
                        <v-list-item-icon>
                            <v-icon :small="small">mdi-archive-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-subtitle>Archives</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="canExportCSV" @click="exportAsCSV" :dense="dense">
                        <v-list-item-icon>
                            <v-icon :small="small">mdi-file-delimited-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-subtitle>Export To CSV</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="canBreakdown" @click="breakdownToggle = !breakdownToggle" :dense="dense">
                        <v-list-item-icon>
                            <v-icon :small="small">mdi-counter</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-subtitle>Quantity Breakdowns</v-list-item-subtitle>
                            <BT-Select-List-Dialog
                                @ok="(list) => { mMeasurements = list }"
                                hideButton
                                itemText="measurementName"
                                itemValue="id"
                                label="Measurements"
                                multiple
                                navigation="measurements"
                                :showToggle="breakdownToggle"
                                width="350" />
                        </v-list-item-content>
                    </v-list-item>
                    <slot name="bladeSettings" v-bind="data" />
                </v-list>
            </v-menu>
        </v-row>
        <v-slide-y-transition group>
            <v-row v-if="canSearchLocal" dense key="3.3" class="my-0">
                <v-btn
                    v-if="canSearchLocal || canSearch"
                    :small="small"
                    icon 
                    @click="showSearch = !showSearch">
                    <v-icon v-if="!showSearch" small>mdi-magnify</v-icon>
                    <v-icon v-else small>mdi-close</v-icon>
                </v-btn>
                <v-slide-x-transition>
                    <v-text-field
                        v-if="(canSearch || canSearchLocal) && showSearch"
                        flat
                        dense
                        hide-details
                        hide-no-data
                        solo-inverted
                        small
                        single-line
                        prepend-inner-icon="mdi-close"
                        append-icon="mdi-magnify"
                        label="Find"
                        v-model="searchInput"
                        @click:prepend-inner="searchInput = null"
                        @click:append="refresh" />
                </v-slide-x-transition>
            </v-row>
            <div key="3.4">
                <slot name="everything" v-bind="{ data, items: filteredItems }">
                    <v-slide-x-transition>
                        <v-list v-if="((data.isMobile && useListItems) || showList) && !showTable" key="3.5" :height="height" class="overflow-y-auto">
                            <v-list-item-group :active-class="activeClass">
                                <template v-for="(item, index) in filteredItems">
                                    <v-list-item
                                        @click="selectItem(item)"
                                        :key="index"
                                        :two-line="twoLine"
                                        :three-line="threeLine"
                                        :dense="dense">
                                        <slot name="listItem" v-bind="{ item, data }">
                                            <v-list-item-icon v-if="mobileFilteredIcon != null">
                                                <slot :name="mobileFilteredIcon.value" v-bind:item="item" v-bind:data="data" v-bind:measurements="computedMeasurements">
                                                    <span v-if="mobileFilteredIcon.prefix != null">{{ mobileFilteredIcon.prefix }}</span>
                                                    <span v-if="mobileFilteredIcon.bool == true">
                                                        <v-icon 
                                                            v-if="getNestedValue(item, mobileFilteredIcon.value) === true"
                                                            icon
                                                            small>mdi-check</v-icon>
                                                    </span>
                                                    <BT-Increment-Quantity-Edit
                                                        v-else-if="mobileFilteredIcon.breakdown === true"
                                                        :spanClass="mobileFilteredIcon.class"
                                                        :isEditing="isEditing"
                                                        :value="getNestedValue(item, mobileFilteredIcon.value)"
                                                        :productID="item.productID"
                                                        :measurements="computedMeasurements" />
                                                    <span
                                                        v-else-if="mobileFilteredIcon.textFilter != null"
                                                        :class="mobileFilteredIcon.class">{{ $options.filters[mobileFilteredIcon.textFilter](getNestedValue(item, mobileFilteredIcon.value)) }}</span>
                                                    <span
                                                        v-else
                                                        :class="mobileFilteredIcon.class">{{ getNestedValue(item, mobileFilteredIcon.value) }}</span>
                                                </slot>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title v-for="(listItem, index) in mobileFilteredTitles" :key="index + 'title'">
                                                    <slot :name="listItem.value" v-bind:item="item" v-bind:data="data" v-bind:measurements="computedMeasurements">
                                                        <span v-if="listItem.prefix != null">{{ listItem.prefix }}</span>
                                                        <span v-if="listItem.bool == true">
                                                            <v-icon 
                                                                v-if="getNestedValue(item, listItem.value) === true"
                                                                icon
                                                                small>mdi-check</v-icon>
                                                        </span>
                                                        <BT-Increment-Quantity-Edit
                                                            v-else-if="listItem.breakdown === true"
                                                            :spanClass="listItem.class"
                                                            :isEditing="isEditing"
                                                            :value="getNestedValue(item, listItem.value)"
                                                            :productID="item.productID" 
                                                            :measurements="computedMeasurements" />
                                                        <span 
                                                            v-else-if="listItem.textFilter != null"
                                                            :class="listItem.class">{{ $options.filters[listItem.textFilter](getNestedValue(item, listItem.value)) }}</span>
                                                        <span 
                                                            v-else
                                                            :class="listItem.class">{{ getNestedValue(item, listItem.value) }}</span>
                                                    </slot>
                                                </v-list-item-title>
                                                <v-list-item-subtitle v-for="(listItem, index) in mobileFilteredSubtitles" :key="index + 'subtitle'">
                                                    <slot :name="listItem.value" v-bind:item="item" v-bind:data="data" v-bind:measurements="computedMeasurements">
                                                        <span v-if="listItem.prefix != null">{{ listItem.prefix }}</span>
                                                        <span v-if="listItem.bool == true">
                                                            <v-icon 
                                                                v-if="getNestedValue(item, listItem.value) === true"
                                                                icon
                                                                small>mdi-check</v-icon>
                                                        </span>
                                                        <BT-Increment-Quantity-Edit
                                                            v-else-if="listItem.breakdown === true"
                                                            :spanClass="listItem.class"
                                                            :isEditing="isEditing"
                                                            :value="getNestedValue(item, listItem.value)"
                                                            :productID="item.productID" 
                                                            :measurements="computedMeasurements" />
                                                        <span 
                                                            v-else-if="listItem.textFilter != null"
                                                            :spanClass="listItem.class">{{ $options.filters[listItem.textFilter](getNestedValue(item, listItem.value)) }}</span>
                                                        <span 
                                                            v-else
                                                            :spanClass="listItem.class">{{ getNestedValue(item, listItem.value) }}</span>
                                                    </slot>
                                                </v-list-item-subtitle>
                                                <!-- <v-list-item-subtitle dense>
                                                    
                                                </v-list-item-subtitle> -->
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-row dense class="mt-0 pt-0">
                                                    <slot name="itemActions" v-bind:index="index" v-bind:item="item" v-bind:items="asyncItems" v-bind:data="data" />

                                                    <v-slide-x-transition group>
                                                        <v-icon 
                                                            v-if="item.errorMsg != null"
                                                            :title="item.errorMsg"
                                                            color="warning"
                                                            key="0.5">
                                                            mdi-alert-circle
                                                        </v-icon>
                                                        <v-btn
                                                            v-if="currentExternalPartyLoaded != null && !syncLoading && !syncItemExists(currentExternalPartyLoaded, item.id, syncItems) && canSync"
                                                            @click.stop="pushToParty(item)"
                                                            :title="'Upload to ' + currentExternalPartyLoaded"
                                                            :disabled="item.loadingCount > 0"
                                                            :loading="item.loadingCount > 0"
                                                            icon
                                                            key="1">
                                                            <v-icon
                                                                color="primary"
                                                                small>mdi-cloud-upload</v-icon>
                                                        </v-btn>
                                                        <v-icon 
                                                            v-else-if="currentExternalPartyLoaded && !syncLoading && canSync"
                                                            color="primary"
                                                            small
                                                            title="Uploaded"
                                                            key="2"
                                                            class="mx-1">mdi-check</v-icon>
                                                    
                                                        <v-btn
                                                            v-if="isDeletableItem(item)"
                                                            icon
                                                            :disabled="!permittedToEdit || item.loadingCount > 0"
                                                            :loading="item.loadingCount > 0"
                                                            @click.stop="deleteItem(item)"
                                                            key="3">
                                                            <v-icon
                                                                color="primary" 
                                                                small>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </v-slide-x-transition>
                                                </v-row>
                                            </v-list-item-action>
                                            <!-- <div>{{ itemText ? getNestedValue(item, itemText) : item }}</div> -->
                                        </slot>
                                    </v-list-item>
                                    <v-divider v-if="dividers" :key="'d' + index" />
                                </template>
                            </v-list-item-group>
                            <div>
                                <slot name="noItems" />
                            </div>
                        </v-list>
                        <v-data-table
                            v-else
                            :dense="dense"
                            :headers="tableHeaders"
                            :height="height"
                            :items="filteredItems"
                            :hide-default-footer="hideFooter || useServerPagination"
                            :hide-default-header="hideTableHeader"
                            :items-per-page="itemsPerPage"
                            mobile-breakpoint="10"
                            @click:row="selectItem"
                            key="4">

                            <template v-slot:[`no-data`]>
                                <slot name="noItems" />
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <slot name="itemActions" v-bind:item="item" v-bind:items="asyncItems" v-bind:data="data">

                                </slot>
                                <v-slide-x-transition group>
                                    <v-icon 
                                        v-if="item.errorMsg != null"
                                        :title="item.errorMsg"
                                        color="warning"
                                        key="0.5">
                                        mdi-alert-circle
                                    </v-icon>
                                    <v-btn
                                        v-if="currentExternalPartyLoaded != null && !syncLoading && !syncItemExists(currentExternalPartyLoaded, item.id, syncItems) && canSync"
                                        @click.stop="pushToParty(item)"
                                        :title="'Upload to ' + currentExternalPartyLoaded"
                                        :disabled="item.loadingCount > 0"
                                        :loading="item.loadingCount > 0"
                                        icon
                                        key="1">
                                        <v-icon
                                            color="primary"
                                            small>mdi-cloud-upload</v-icon>
                                    </v-btn>
                                    <v-icon 
                                        v-else-if="currentExternalPartyLoaded && !syncLoading && canSync"
                                        color="primary"
                                        small
                                        title="Uploaded"
                                        key="2"
                                        class="mx-1">mdi-check</v-icon>
                                
                                    <v-btn
                                        v-if="isDeletableItem(item)"
                                        icon
                                        :disabled="!permittedToEdit || item.loadingCount > 0"
                                        :loading="item.loadingCount > 0"
                                        @click.stop="deleteItem(item)"
                                        key="3">
                                        <v-icon
                                            color="primary" 
                                            small>mdi-delete</v-icon>
                                    </v-btn>
                                </v-slide-x-transition>
                            </template>

                            <template v-for="(filter, index) in allDisplayFilters" v-slot:[`item.${filter.value}`]="{ item }">
                                <span :key="index">
                                    <slot :name="filter.value" v-bind:item="item" v-bind:data="data" v-bind:measurements="computedMeasurements">
                                        <div v-if="filter.bool == true">
                                            <v-icon 
                                                v-if="getNestedValue(item, filter.value) === true"
                                                icon
                                                small>mdi-check</v-icon>
                                        </div>
                                        <BT-Increment-Quantity-Edit
                                            v-else-if="filter.breakdown === true"
                                            :spanClass="filter.class"
                                            :isEditing="isEditing"
                                            :value="getNestedValue(item, filter.value)"
                                            :productID="item.productID" 
                                            :measurements="computedMeasurements" />
                                        <div 
                                            v-else-if="filter.textFilter != null && filter.selfIsValue === true"
                                            :class="filter.class">{{ $options.filters[filter.textFilter](item) }}</div>
                                        <div 
                                            v-else-if="filter.textFilter != null"
                                            :class="filter.class">{{ $options.filters[filter.textFilter](getNestedValue(item, filter.value)) }}</div>
                                        <div 
                                            v-else
                                            :class="filter.class">Dummy</div>
                                    </slot>
                                </span>
                            </template>

                        </v-data-table>
                    </v-slide-x-transition>
                </slot>
            </div>
            <div v-if="useServerPagination && totalPages > 1" key="5">
                <v-divider class="mb-3" />
                <v-pagination
                    v-model="currentPage"
                    :length="totalPages" />
            </div>

            <v-overlay :value="isLoading" absolute key="6" class="text-center">
                <v-progress-circular indeterminate size="32" />
                <p>Loading</p>
            </v-overlay>

            <v-dialog v-model="showDelete" max-width="500px" key="7">
                <v-card>
                    <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-alert v-model="showError" dismissible type="error" key="3">{{ errorMessage }}</v-alert>
        </v-slide-y-transition>
        <BT-Snack v-model="msg" />
    </div>
</template>

<script>
import { roundUp, getBreakdown } from '~helpers';
import { firstBy } from 'thenby';

export default {
    name: 'BT-Expansion-Items',
    components: {
        BTIncrementQuantityEdit: () => import('~components/BT-Increment-Quantity-Edit.vue'),
        BTSelectListDialog: () => import('~components/BT-Select-List-Dialog.vue')
    },
    data: function() {
        return {
            asyncItems: [],
            asyncExternalParties: [],
            availableExternalParties: [],
            bladeData: null,
            breakdownToggle: false,
            currentExternalParty: null,
            currentExternalPartyLoaded: null,
            currentPage: 1,
            errorMessage: null,
            id: null,
            isEditing: false,
            isFilterChanged: false,
            isLoaded: false,
            isLoading: false,
            mMeasurements: [],
            mParams: { includeDetails: false },
            mProxyID: null,
            msg: null,
            originalDataBag: null,
            originalFilters: [],
            recentConditions: null, //that last set of conditions to have been pulled ({ ...params, ...navigation, ...bladeData, ...proxyID })
            searchString: null,
            searchInput: null,
            selectedFilters: [],
            select: null,
            selectedItem: null,
            showDelete: false,
            showError: false,
            showSearch: false,
            syncItems: [],
            syncLoading: false,
            totalPages: 0,
        }
    },
    props: {
        activeClass: {
            type: String,
            default: null
        },
        addBladeName: null,
        addTrainingBladeName: null,
        addTo: null,
        actionsWidth: null,
        archiveBladeName: null,
        bladeName: null,
        bladesData: {
            type: Object,
            default: null
        },
        bladesToClear: {
            type: Array,
            default: null
        },
        canAdd: {
            type: Boolean,
            default: false
        },
        canBreakdown: {
            type: Boolean,
            default: false
        },
        canClose: {
            type: Boolean,
            default: true
        },
        canDelete: {
            type: Boolean,
            default: false
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        canExportCSV: {
            type: Boolean,
            default: false
        },
        canRefresh: {
            type: Boolean,
            default: true,
        },
        canSearch: {
            type: Boolean,
            default: true
        },
        canSearchLocal: {
            type: Boolean,
            default: false
        },
        canSelect: {
            type: Boolean,
            default: true
        },
        canSync: {
            type: Boolean,
            default: true
        },
        csvIncludeTitle: {
            type: Boolean,
            default: false
        },
        customFilters: { //a list of custom filters which when selected adjust the query parameter
            type: Array,
            default: () => { return [] } //{ filter: String, filterFunction: () => { return String }, paramProp: (default = 'query') }
        },
        customURL: {
            type: String,
            default: null
        },
        defaultFilters: { //a list of strings which are names of filters which start as selected
            type: Array,
            default: null
        },
        dense: {
            type: Boolean,
            default: true
        },
        dividers: {
            type: Boolean,
            default: true
        },
        filters: {
            type: Array,
            default: null
        },
        flat: {
            type: Boolean,
            default: true
        },
        getParams: {
            type: Function,
            default: null
        },
        getNewBladeData: {
            type: Function,
            default: null
        },
        getNewQuery: {
            type: Function,
            default: null
        },
        getSelectBladeData: {
            type: Function,
            default: null
        },
        getSelectQuery: {
            type: Function,
            default: null
        },
        headers: {
            type: Array, //{ text, textFilter, value, valueFilter, breakdown, csv, csvArray, csvText, csvFilter, csvBreakdown, csvProductIDProp }
            default: null
        },
        height: {
            type: String,
            default: null
        },
        hideActions: {
            type: Boolean,
            default: false,
        },
        hideFooter: {
            type: Boolean,
            default: true
        },
        hideHeader: {
            type: Boolean,
            default: false
        },
        hideTableHeader: {
            type: Boolean,
            default: false
        },
        isSingle: {
            type: Boolean,
            default: false
        },
        itemID: {
            type: String,
            default: null
        },
        items: {
            type: Array,
            default: null
        },
        itemsPerPage: {
            type: Number,
            default: 75
        },
        itemProperties: {
            type: Array,
            default: null
        },
        itemText: {
            type: String,
            default: null
        },
        itemValue: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        measurements: {
            type: Array,
            default: null
        },
        mobileWidth: {
            type: Number,
            default: null
        },
        navigation: null,
        onCanDelete: {
            type: Function,
            default: null
        },
        onCanOpenBlade: {
            type: Function,
            default: null
        },
        onCanPull: {
            type: Function,
            default: null
        },
        onCanSelect: {
            type: Function,
            default: null
        },
        onDelete: {
            type: Function,
            default: null
        },
        onFilter: {
            type: Function,
            default: null
        },
        onInsertDataAsync: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        onSelect: {
            type: Function,
            default: null
        },
        params: {
            type: Object,
            default: null
        },
        proxyID: {
            type: String,
            default: null
        },
        proxyIDParam: {
            type: String,
            default: 'proxyID'
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        searchProps: {
            type: Array,
            default: null
        },
        showList: {
            type: Boolean,
            default: false
        },
        showSettings: {
            type: Boolean,
            default: false
        },
        showTable: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: true
        },
        syncNavigation: null,
        threeLine: {
            type: Boolean,
            default: false
        },
        title: null,
        twoLine: {
            type: Boolean,
            default: false
        },
        useListItems: {
            type: Boolean,
            default: true
        },
        useServerFilters: {
            type: Boolean,
            default: true
        },
        useServerPagination: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        currentPage: function() {
            this.pullItems();
        },
        items: function() {
            this.pullItems();
        },
        loading: function(val) {
            this.isLoading = val;
            this.$forceUpdate();
        },
        params: function(val) {
            if (JSON.stringify(val) !== JSON.stringify(this.mParams)) {
                this.mParams = val;
            }
        },
        refreshToggle: function() {
            this.refresh();
        },
        selectedFilters: function(val) {
            this.isFilterChanged = JSON.stringify(val) != JSON.stringify(this.originalFilters);
        },
    },
    created() {
        this.isLoading = this.loading;

        if (this.params != null) {
            this.mParams = this.params;
        }
    },
    mounted() {
        if (this.params != null) {
            this.mParams = this.params;
        }

        this.$emit('mounted');

        if (this.isLengthyArray(this.defaultFilters)) {
            this.defaultFilters.forEach(dFilter => {
                this.selectedFilters.push(this.chipFilters.findIndex(y => y == dFilter));
            })
            this.originalFilters = this.selectedFilters;
        }

        this.pullItems();
    },
    computed: {
        addBladeNameC() {
            if (this.addTrainingBladeName == null || !this.$BlitzIt.navigation.isTraining()) {
                return this.addBladeName;
            }
            else {
                return this.addTrainingBladeName;
            }
        },
        allDisplayFilters() {
            return this.tableHeaders.filter(x => x.textFilter != null || x.display != null || x.breakdown === true || x.bool === true); // || this.displayFilters.some(y => y == x.value));
        },
        chipFilters() {
            var filterList = [];
            
            if (this.isLengthyArray(this.customFilters)) {
                filterList.push(...this.customFilters.map(x => x.filter));
            }

            if (this.useServerFilters && this.navigation != null) {
                filterList.push(...this.$BlitzIt.store.filters(this.navigation));
            }

            return filterList;
        },
        computedBladeData() {
            return {
                data: {
                    id: this.$route.params['id'],
                    ...this.originalDataBag
                }
            }
        },
        computedMeasurements() {
            return this.measurements != null ? this.measurements : this.mMeasurements;
        },
        csvItems() { //{ csv: true, () => {}, string,  }
            //return this.headers;
            if (!this.isLengthyArray(this.headers)) {
                return [];
            }
            return this.headers
                //.filter(y => (y.csvText != null || (y.text != null && y.csv !== false)) && (y.csv != undefined || y.csvFilter != undefined))
                .filter(y => ((y.csvText != undefined || y.text != undefined) && y.csv != undefined) || y.csv != undefined || y.csvText != undefined || y.csvFilter != undefined || y.csvBreakdown != undefined || y.csvArray != undefined)
                .map(z => {
                    return Object.assign({}, z, {
                        header: z.csvText != null ? z.csvText : z.text,
                        value: (z.csv === true || z.csv == undefined) ? z.value : z.csv,
                        valueFilter: z.csvFilter,
                        breakdown: z.csvBreakdown === true,
                        csvArray: z.csvArray === true
                    });
                });
        },
        data() {
            return {
                isEditing: this.isEditing,
                isMobile: this.isMobileWidth,
                ...this.computedBladeData,
            }
        },
        externalOptions() {
            return this.syncNavigation != null ? this.$BlitzIt.navigation.findExternalParties(this.syncNavigation) : [];
        },
        filteredItems() {
            var l = this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
            
            if (this.canSearchLocal && this.searchInput != null) {
                l = l.filter(x => this.hasSearch(x, this.searchInput, this.searchableProperties));
            }

            return l;
        },
        isMobileWidth() {
            return this.$vuetify.breakpoint.mobile;
        },
        mobileFilteredIcon() {
            return this.tableHeaders.find(x => x.isIcon);
        },
        mobileFilteredTitles() {
            return this.tableHeaders.filter(x => x.title != null).sort(firstBy(x => x.title));
        },
        mobileFilteredSubtitles() {
            return this.tableHeaders.filter(x => x.subtitle != null).sort(firstBy(x => x.subtitle));
        },
        pageTitle() {
            if (this.title) {
                return this.title;
            }
            
            return this.$BlitzIt.navigation.findSingleDisplayName(this.navigation);
        },
        permittedToEdit() {
            return this.navigation != null ? this.$BlitzIt.auth.canEdit(this.navigation) : true;
        },
        proxyCompanyID() {
            if (this.proxyID != null) {
                return this.proxyID;
            }
            else if (this.mProxyID != null) {
                return this.mProxyID;
            }
            else if (this.proxyIDParam != null && this.bladeData != null && this.bladeData.data != null && this.bladeData.data[this.proxyIDParam] != null) {
                return this.bladeData.data[this.proxyIDParam];
            }
            else if (this.proxyIDParam != null && this.$route.query != null && this.$route.query[this.proxyIDParam] != null) {
                return this.$route.query[this.proxyIDParam];
            }

            return null;
        },
        searchableProperties() {
            var l = this.tableHeaders.filter(x => x.searchable != null).map(x => x.value);
            if (this.isLengthyArray(this.searchProps)) {
                l = [...new Set(l.concat(this.searchProps))];
            }

            return l;
        },
        showAddButton() {
            return this.canAdd;
        },
        showEditButton() {
            return this.canEdit && this.$BlitzIt.auth.canEdit(this.navigation);
        },
        showRefreshButton() {
            return this.canRefresh;
        },
        syncItemExists: () => (partyID, itemID, syncItems) => {
            if (syncItems != undefined && syncItems != null) {
                return syncItems.find(x => x.externalPartyID == partyID && x.localID == itemID) != null;
            }
            else {
                return false;
            }
        },
        tableHeaders() {
            var rHeaders = [];
            if (this.isLengthyArray(this.headers)) {
                for (var i = 0; i < this.headers.length; i++) {
                    if (this.headers[i].text != null) {
                        rHeaders.push(this.headers[i]);
                    }
                }
            }
            if (!this.hideActions) {
                rHeaders.push({ text: 'Actions', value: 'actions', align: 'right', width: this.actionsWidth || 'auto' });
            }
            return rHeaders;
        }
    }, 
    methods: {
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        startLoading() {
            this.isLoading = true;
            this.$forceUpdate();
        },
        endLoading() {
            this.isLoading = false;
            this.$forceUpdate();
        },
        add() {
            if (this.addBladeNameC != null) {
                var newBlade = { 
                    bladeName: this.addBladeNameC,
                    data: { id: 'new' },
                    query: {},
                    bladesToClear: this.bladesToClear
                };

                if (this.getNewBladeData != null) {
                    newBlade.data = Object.assign({}, newBlade.data, this.getNewBladeData(this.computedBladeData));
                }

                if (this.proxyCompanyID != null) {
                    newBlade.query.proxyID = this.proxyCompanyID;
                }

                if (this.getNewQuery != null) {
                    newBlade.query = Object.assign({}, newBlade.query, this.getNewQuery(this.computedBladeData));
                }

                if (this.bladesData != null) {
                    this.bladesData.openBlade(newBlade);
                }
                else {
                    console.log(newBlade);
                    this.$router.push({
                        name: newBlade.bladeName,
                        params: newBlade.data,
                        query: newBlade.query
                    });
                }
            }
        },
        closeDelete() {
            this.selectedItem = null;
            this.showDelete = false;
        },
        conditionsHaveChanged() { //checks and returns true or false, always ending with updating the recentConditions with the currentConditions
            var currentConditions = {
                ...this.getParamObj(),
                navigation: this.navigation,
                itemID: this.itemID,
                proxyID: this.proxyCompanyID,
                ...this.bladeData != null ? this.bladeData.data : null
            }
            
            var currentJSON = JSON.stringify(currentConditions);
            var isDif = currentJSON != this.recentConditions;
            this.recentConditions = currentJSON;
            return isDif;
        },
        deleteItem(item) {
            this.selectedItem = item;
            this.showDelete = true;
        },
        async deleteItemConfirm() {
            var item = this.selectedItem;
            
            if (this.onDelete != null) {
                item.loadingCount += 1;
                this.$forceUpdate();

                this.onDelete(item);

                if (item != null) {
                    item.loadingCount -= 1;
                }
            }
            else {
                var asyncInd = this.asyncItems.findIndex(y => y.id == item.id);
                if (this.navigation != null) {
                    item.loadingCount += 1;
                    this.$forceUpdate();

                    try {
                        await this.$BlitzIt.store.delete(this.navigation, item.id, this.proxyCompanyID);
                    }
                    catch (err) {
                        this.formError(err);
                    }
                    finally {
                        if (item != null) {
                            item.loadingCount -= 1;
                        }
                        if (asyncInd > -1) {
                            this.asyncItems.splice(asyncInd, 1);
                        }
                    }
                }       
                else {
                    var ind = this.items.findIndex(y => y.id == item.id);
                    if (ind >= 0) {
                        this.items.splice(ind, 1);
                    }
                    if (asyncInd > -1) {
                        this.asyncItems.splice(asyncInd, 1);
                    }
                }
            }
            this.showDelete = false;
        },
        async exportAsCSV() {
            //csvIncludeTitle
            if (this.isLengthyArray(this.asyncItems)) {
                var csvKeys = this.csvItems;
                
                if (!this.isLengthyArray(csvKeys)) {
                    csvKeys = Object.keys(this.asyncItems[0]);
                    csvKeys = csvKeys.map(x => { return { header: this.fromCamelCase(x), value: x }; });
                }

                var increments = [];
                if (csvKeys.some(y => y.breakdown === true)) {
                    try {
                        increments = await this.$BlitzIt.store.getAll('stock-increments');
                    }
                    catch (err) {
                        console.log(this.extractErrorDescription(err));
                    }
                }

                var csvList = [];
                this.filteredItems.forEach(aItem => {
                    var newItem = {};
                    var otherLines = [];
                    csvKeys.forEach(csvDNA => {
                        //object
                        var v = null;
                        if (typeof(csvDNA.value) == 'function') {
                            //newItem[csvDNA.header] = csvDNA.value(aItem);
                            v = csvDNA.value(aItem);
                        }
                        else if (typeof(csvDNA.value) == 'string') {
                            v = this.getNestedValue(aItem, csvDNA.value);
                        }

                        if (v != null && csvDNA.valueFilter != null) {
                            v = this.$options.filters[csvDNA.valueFilter](v);
                        }

                        if (csvDNA.csvArray) {
                            if (this.isLengthyArray(v)) {
                                v.forEach(w => {
                                    otherLines.push(w.toString());
                                })
                            }
                        }
                        else {
                            if (csvDNA.breakdown) {
                                var prodProp = csvDNA.csvProductIDProp || 'productID';
                                newItem[csvDNA.header] = getBreakdown(v, this.mMeasurements, increments, aItem[prodProp]);
                            }
                            else {
                                newItem[csvDNA.header] = v;
                            }
                        }
                    });
                    csvList.push(newItem);

                    if (this.isLengthyArray(otherLines)) {
                        otherLines.forEach(l => {
                            csvList.push(l);
                        })
                    }
                    
                    otherLines = [];

                });
                this.generateCSVFile(csvList, 'csvData.csv', csvKeys.map(x => x.header), this.csvIncludeTitle ? this.title : null);
            }
        },
        getParamObj() {
            var paramObj = this.copyDeep(this.mParams);

            if (this.searchString && this.searchString.length > 0) {
                if (this.canSearch) {
                    paramObj.searchString = this.searchString;
                }
            }
            
            if (this.isLengthyArray(this.selectedFilters)) {
                var serverFilters = [];
                var customFilters = [];
                this.selectedFilters.forEach(selectedIndex => {
                    var sFilter = this.chipFilters[selectedIndex];
                    if (this.isLengthyArray(this.customFilters) && this.customFilters.some(cF => cF.filter == sFilter)) {
                        customFilters.push(this.customFilters.find(cF => cF.filter == sFilter));
                    }
                    else {
                        serverFilters.push(sFilter);
                    }
                });

                if (this.isLengthyArray(serverFilters)) {
                    paramObj.filterBy = serverFilters.toString();
                }
                
                if (this.isLengthyArray(customFilters)) {
                    var query = '';
                    customFilters.forEach(cFilter => {
                        if (cFilter.paramProp == null || cFilter.paramProp == 'query') {
                            query = query + (query.length > 0 ? 'ANDALSO' : '') + (cFilter.filterFunction());
                        }
                        else {
                            paramObj[cFilter.paramProp] = cFilter.filterFunction();
                        }
                    })

                    if (query.length > 0) {
                        paramObj.query = query;
                    }
                }
            }
            
            if (this.isLengthyArray(this.itemProperties)) {
                paramObj.properties = this.copyDeep(this.itemProperties).toString();
            }

            if (this.useServerPagination) {
                paramObj.includeCount = true;
                paramObj.takeAmount = this.itemsPerPage;
                paramObj.takeFrom = (this.currentPage - 1) * this.itemsPerPage;
            }

            return paramObj;
        },
        isDeletableItem(item) {
            if (!this.canDelete) {
                return false;
            }
            if (this.onCanDelete) {
                return this.onCanDelete(item);
            }
            else {
                return this.canDelete;
            }
        },
        loadCount() {
            if (this.navigation != null) {
                var cnt = this.$BlitzIt.store.getLocalCount(this.navigation);            
                this.totalPages = roundUp(cnt / this.itemsPerPage);            
            }
        },
        async loadSyncItems() {
            if (this.currentExternalParty == null) {
                this.currentExternalPartyLoaded = null;
            }
            else if (this.currentExternalParty != this.currentExternalPartyLoaded) {
                //change to current external party
                if (this.syncItems == null || this.syncItems.length == 0) {
                    var itemIDs = this.asyncItems.map(x => x.id);

                    if (this.syncNavigation != null && this.isLengthyArray(itemIDs)) {
                        try {
                            this.syncLoading = true;
                            this.$forceUpdate();

                            this.syncItems = await this.$BlitzIt.store.getSyncItems(this.syncNavigation, [this.currentExternalParty], itemIDs);
                            this.currentExternalPartyLoaded = this.currentExternalParty;
                        }
                        catch (err) {
                            this.formError(err);
                        }
                        finally {
                            this.syncLoading = false;
                            this.$forceUpdate();
                        }
                    }
                }
            }
        },
        async pullExternalParties() {
            if (!this.canSync || !this.syncNavigation) {
                this.asyncExternalParties = [];
                return;
            }

            var eParties = this.$BlitzIt.navigation.findExternalParties(this.syncNavigation);

            if (this.isLengthyArray(eParties)) {
                var extPath = this.$BlitzIt.navigation.findExternalPartyCredentialNavigation(this.syncNavigation);
                
                if (!extPath) {
                    this.asyncExternalParties = [];
                }

                try {
                    this.syncLoading = true;
                    this.$forceUpdate();
                
                    var res = await this.$BlitzIt.store.getAll(extPath);
                    var rList = [];                           
                    for (let i = 0; i < res.length; i++) {
                        const element = res[i];
                        var cred = eParties.find(x => x === element.externalPartyOptionID);
                        if (cred) {
                            rList.push(cred);
                        }
                    }

                    this.asyncExternalParties = rList;
                }
                catch (err) {
                    this.formError(err);
                    this.asyncExternalParties = [];
                }
                finally {
                    this.syncLoading = false;
                    this.$forceUpdate();
                }
            }
        },
        async pullItems(refresh = false) {
            if (this.onCanPull != null && !this.onCanPull()) {
                return;
            }
            
            this.isLoaded = true;

            if (this.items != null) {
                var resL = this.items.map(x => Object.assign(x, { loadingCount: 0, errorMsg: null }));
                
                if (this.onPullSuccessAsync != null) {
                    this.asyncItems = await this.onPullSuccessAsync(resL, refresh, this.bladeData);
                }
                else {
                    this.asyncItems = resL;
                }
                
                if (this.onInsertDataAsync != null) {
                    this.originalDataBag = await this.onInsertDataAsync(this.asyncItems);
                }

                return;
            }

            if (this.navigation == null || this.mParams == null) {
                return;
            }

            try {
                this.startLoading();

                var paramObj = this.getParamObj();

                var res = null;
                if (this.isSingle) {
                    var idParam = this.itemID != null ? this.itemID : this.id;
                    res = await this.$BlitzIt.store.get(this.navigation, idParam, paramObj, refresh, this.proxyCompanyID, this.customURL);
                }
                else {
                    res = await this.$BlitzIt.store.getAll(this.navigation, paramObj, refresh, this.proxyCompanyID, this.customURL);
                }
                
                if (this.isLengthyArray(res)) {
                    res = res.map(x => Object.assign(x, { loadingCount: 0, errorMsg: null }));
                }
                
                if (this.onPullSuccessAsync != null) {
                    this.asyncItems = await this.onPullSuccessAsync(res, refresh, this.bladeData);
                }
                else {
                    this.asyncItems = res;
                }
                
                if (this.onInsertDataAsync != null) {
                    this.originalDataBag = await this.onInsertDataAsync(this.asyncItem);
                }

                this.$emit('fetched', this.asyncItems);
                this.loadCount();
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.endLoading();
            }
        },
        async pushToParty(item) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();
            
                var returnItem = await this.$BlitzIt.store.pushSyncItem(this.syncNavigation, item.id, this.currentExternalPartyLoaded);
                if (returnItem.message != null) {
                    item.errorMsg = returnItem.message;
                }
                else {
                    this.syncItems.push({ localID: item.id, externalPartyID: this.currentExternalPartyLoaded });
                }
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                item.loadingCount -= 1;
                this.$forceUpdate();
            }
        },
        refresh(refresh = true) {
            this.currentPage = 1;
            this.totalPages = 0;
            this.originalFilters = this.selectedFilters;
            this.isFilterChanged = JSON.stringify(this.selectedFilters) != JSON.stringify(this.originalFilters);
            this.searchString = this.searchInput;
            this.showSearch = false;
            this.showError = false;
            this.errorMessage = null;

            //reset syncing
            this.currentExternalPartyLoaded = null;
            this.currentExternalParty = null;
            this.syncItems = null;

            this.pullItems(refresh);
        },
        reset() {
            this.currentPage = 1;
            this.totalPages = 0;
            this.originalFilters = [];
            this.selectedFilters = [];
            this.searchString = null;
            this.searchInput = null;

            if (this.isLengthyArray(this.defaultFilters)) {
                this.defaultFilters.forEach(dFilter => {
                    this.selectedFilters.push(this.chipFilters.findIndex(y => y == dFilter));
                })
                this.originalFilters = this.selectedFilters;
            }
            
            this.$BlitzIt.store.clear(this.navigation);
            this.pullItems(true);
        },
        selectItem(item) {
            if (!this.canSelect || (this.onCanSelect != null && !this.onCanSelect(item))) {
                return;
            }
            
            if (this.onSelect != null) {
                this.onSelect(this.bladeData, item);
            }
            else if (item == null) {
                if (this.bladesData != null) {
                    this.bladesData.closeBlade({ bladeName: this.addBladeNameC });
                }
            }
            else {
                var selectBlade = { 
                    bladeName: this.addBladeNameC, 
                    data: { id: item.id },
                    query: {}
                };

                if (this.getSelectBladeData != null) {
                    selectBlade.data = Object.assign({}, selectBlade.data, this.getSelectBladeData(this.bladeData, item, this.computedMeasurements));
                }
                
                if (this.proxyCompanyID != null) {
                    selectBlade.query.proxyID = this.proxyCompanyID;
                }

                if (this.getSelectQuery != null) {
                    selectBlade.query = Object.assign({}, selectBlade.query, this.getSelectQuery(this.bladeData, item, this.computedMeasurements));
                }
                
                if (this.onCanOpenBlade != null) {
                    var str = this.onCanOpenBlade(selectBlade);
                    if (str === false) {
                        return;
                    }
                    else if (typeof(str) === 'string') {
                        this.msg = str;
                        return;
                    }
                }
                
                if (this.bladesData == null) {
                    this.$router.push({ 
                        name: selectBlade.bladeName, 
                        params: selectBlade.data, 
                        query: selectBlade.query 
                    });
                }
                else {
                    this.bladesData.openBlade(selectBlade);
                }
            }
        },
        syncExists(partyID, itemID) {
            if (this.syncItems != undefined && this.syncItems != null) {
                return this.syncItems.find(x => x.externalPartyID == partyID && x.localID == itemID) != null;
            }
            else {
                return false;
            }
        },
        toArchives() {
            if (this.bladesData != null) {
                this.bladesData.openBlade({ bladeName: this.archiveBladeName });
            }
            else {
                this.$router.push({ name: this.archiveBladeName });
            }
        },
        toggleEdit() {
            this.isEditing = !this.isEditing;
            if (this.isEditing) {
                this.$emit('edit', this.computedBladeData);
            }
        }
    }
}
</script>